import { forwardRef, useMemo } from "react"
import ReactSelect from "react-select"
import Label from "@/components/common/forms/Label"
import {
  ReactSelectCustomStyles,
  ReactSelectCustomTheme,
  ReactSelectDropdownIndicator,
  ReactSelectMultiValueLabel,
  ReactSelectMultiValueOption,
  ReactSelectMultiValueRemove,
} from "@/components/common/forms/react-select-helpers"

export const Multiselect = forwardRef(function MultiSelectRef(
  { label, name, options, value, defaultValue, isLoading, required, ...rest },
  ref
) {
  const finalValues = useMemo(
    () =>
      (value || defaultValue)?.map?.((v) => {
        const option = options?.find((o) => o.value === (v.value || v))

        return {
          value: option?.value || option,
          label: option?.label || "",
          ...option,
        }
      }),
    [options, value, defaultValue]
  )

  return (
    <div>
      {label && (
        <Label htmlFor={name}>{`${label}${required ? " *" : ""}`}</Label>
      )}

      <div style={{ position: "relative", marginBottom: 22 }}>
        <ReactSelect
          id={name}
          name={name}
          options={options}
          isMulti
          defaultValue={defaultValue}
          isClearable={false}
          isSearchable={false}
          hideSelectedOptions={true}
          noOptionsMessage={() =>
            isLoading ? "Loading..." : "No unselected options"
          }
          styles={ReactSelectCustomStyles}
          components={{
            DropdownIndicator: ReactSelectDropdownIndicator,
            MultiValueLabel: ReactSelectMultiValueLabel,
            MultiValueRemove: ReactSelectMultiValueRemove,
            Option: ReactSelectMultiValueOption,
          }}
          theme={ReactSelectCustomTheme}
          value={finalValues}
          //   menuPosition="fixed"
          ref={ref}
          required={required}
          {...rest}
          closeMenuOnSelect={false}
        />
      </div>
    </div>
  )
})
