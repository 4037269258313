import useWaitlists from "../api-hooks/useWaitlists";
import { useEffect, useMemo } from "react";
import { useTypedSelector } from "@/store/index";
import { setPublicWaitlistId } from "@/store/slices/selectedSlice";
import { useDispatch } from 'react-redux';
import useVirtualWaitingRooms from "../api-hooks/useVirtualWaitingRooms";
import useSelectedLocation from "./useSelectedLocation";

export default function useSelectedWaitlist() {
  const dispatch = useDispatch()
  const { locationId } = useTypedSelector((state) => state.selected)
  const { hasOnlyOneLocation, isUsingSharedSettings} = useTypedSelector((state) => state.pages.manage)
  const { waitlists, isValidating, isLoading, mutateWaitlists } = useWaitlists()
  const { virtualWaitingRooms } = useVirtualWaitingRooms()
  const { selectedLocation } = useSelectedLocation()

  const locationVWR = useMemo(() => {
    return selectedLocation?.isVwrEnabled ? virtualWaitingRooms?.find((vwr) => vwr.vwrableId === locationId && vwr.deletedAt === null) : undefined
  }, [virtualWaitingRooms, locationId, selectedLocation])

  const selectedWaitlist = useMemo(() => {
    if (hasOnlyOneLocation || isUsingSharedSettings || !locationVWR) {
      return waitlists?.find((wl) => wl.waitlistableType === 'business')
    }
    return waitlists.find((wl) => wl.waitlistableId === locationId)
  }, [locationId, waitlists, hasOnlyOneLocation, isUsingSharedSettings, locationVWR])

  useEffect(() => {
    dispatch(setPublicWaitlistId(selectedWaitlist?.id ?? null))
  }, [selectedWaitlist, locationVWR, selectedLocation])

  return {
    selectedWaitlist,
    isWaitlistsValidating: isValidating,
    isWaitlistsLoading: isLoading,
    mutateWaitlists,
    locationVWR,
  }
}