
import { setIsUsingCustomQueueSelector } from '@/store/slices/pages/manageSlice';
import { syncSciSettings } from '@/store/slices/pages/settingsSlice';
import { useTypedSelector } from "../store"
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import useCustomFields from './api-hooks/useCustomFields';
import { isCfPossibleForLocation } from '@/utils/custom-field-utils';
import { getSharedSci, shouldSyncSciSettings } from '@/utils/sci-utils'
import useSelfCheckIns from './api-hooks/useSelfCheckIns';
import useLocations from './api-hooks/useLocations'; 
import { getSelectedObjectById } from '@/utils/util-functions';


const useIsUsingCustomQueueSelector = (isSci: boolean) => {
  const dispatch = useDispatch()
  const { isUsingSharedSettings, hasOnlyOneLocation, isUsingCustomQueueSelector } = useTypedSelector((state) => state.pages.manage)
  const { locationId } = useTypedSelector(state => state.selected)
  const { locations } = useLocations()
  const { customFields } = useCustomFields()
  const { selfCheckIns } = useSelfCheckIns()

  const queueSelectFields = useMemo(() => customFields
    .filter(cf => isCfPossibleForLocation(
      cf,
      locationId,
      isUsingSharedSettings,
      hasOnlyOneLocation,
      isSci
    ))
    .filter(cf => cf.type === 'queueselect'),
    [locationId, isUsingSharedSettings, hasOnlyOneLocation, customFields]
  )

  const selectedLocation = useMemo(() => getSelectedObjectById(locationId, locations), [locations])
  const customQueueCFId = useMemo(() => queueSelectFields[0]?.id, [queueSelectFields])
  const queueSelectorFieldExists = useMemo(() => queueSelectFields.length > 0, [queueSelectFields])
  const sharedSci = useMemo(() => getSharedSci(selfCheckIns), [selfCheckIns])
  const shouldSync = useMemo(() => shouldSyncSciSettings(sharedSci, isUsingCustomQueueSelector, customQueueCFId), [sharedSci, isUsingCustomQueueSelector, customQueueCFId])

  const hydratedSci = useMemo(() => {
    return {
      ...sharedSci,
      geofence: {
        addressId: selectedLocation?.address?.id || "",
        radiusMi: 1,
        isEnabled: false,
      }
    }
  }, [sharedSci, selectedLocation])

  useEffect(() => {
    if (queueSelectorFieldExists !== isUsingCustomQueueSelector) {
      dispatch(setIsUsingCustomQueueSelector(queueSelectorFieldExists))
    }
    if (selectedLocation && queueSelectorFieldExists && shouldSync && hydratedSci?.geofence?.addressId) {
      dispatch(syncSciSettings({
        sci: hydratedSci,
        isUsingCustomQueueSelector: queueSelectorFieldExists,
        customQueueCFId
      }))
    }
    
  }, [queueSelectorFieldExists, queueSelectFields, shouldSync, hydratedSci, selectedLocation, customQueueCFId])

  
  
  return { isUsingCustomQueueSelector: queueSelectorFieldExists }

}

export default useIsUsingCustomQueueSelector