import React, { useEffect, useMemo, useState } from 'react'
import styles from '@/styles/Waitlist.module.scss'
import { useDispatch } from 'react-redux'
import QueueStats from '@/components/pages/waitlist/WaitlistHeader/QueueStats'
import { setSelectedQueueId } from '@/store/slices/selectedSlice'
import { DEFAULT_QUEUE_FILTER } from '@/consts/misc'
import classNames from 'classnames'
// import { Skeleton } from "@mui/material"
import { INACTIVE_STATUSES_LIST } from '@/consts/PartyStatusEnum'
import { useTypedSelector } from '@/store/index'
import { Queue } from '@/api/interface/queue'
import { WaitTime, WaitTimeWithCount } from '@/api/interface/wait-time'
import useFilteredParties from '@/hooks/useFilteredParties'
import { setPageNumber } from '@/store/slices/pages/waitlistSlice'
import {
  getDurationInSeconds,
  getDisplayTimeDuration,
  filterUnits,
} from '@/utils/time-utils'
import BookingStats from './BookingStats'
import useBookingParties from '@/hooks/api-hooks/useBookingParties'

interface Props {
  selectedQueue: Partial<Queue>
  waitTimes: Array<WaitTime>
}

export default function WaitlistHeader({ selectedQueue, waitTimes }: Props) {
  const dispatch = useDispatch()
  const { locationId } = useTypedSelector((state) => state.selected)
  const { filters } = useTypedSelector((state) => state.pages.waitlist)
  const { isHistory, isBooking } = filters

  const {
    parties,
    responseMeta: partiesMeta,
    mutateParties,
  } = useFilteredParties()

  const { bookingCounts, isBookingView } = useBookingParties()

  useEffect(() => {
    //* reset active filter when selected location changes
    dispatch(setSelectedQueueId(DEFAULT_QUEUE_FILTER.id))
  }, [locationId])

  // TODO: - would want counts displayed to match filtered totals
  // but we don't have totals for each queue at once other than in waitTimes
  const waitTimesWithCount: Array<WaitTimeWithCount> = useMemo(
    () =>
      !!parties && !!locationId && !!waitTimes
        ? waitTimes.map((x) => ({
            ...x,
            count: isHistory
              ? x.historicalPartiesInQueue
              : x.waitingPartiesInQueue,
          }))
        : [],
    [waitTimes, parties, locationId, isHistory]
  )

  const allQueuesCount =
    isHistory && partiesMeta?.lastPage > 1
      ? Infinity
      : waitTimesWithCount?.reduce((acc: number, curr) => acc + curr.count, 0)

  // TODO: Can we move this to the queue stats component? Also, roll up seconds into minutes probably? This seems weird
  const computeWaitTimeWithKey = (key: 'waitTimeM' | 'waitTimeS') => {
    const val = waitTimes.reduce((acc, curr) => acc + curr[key], 0)

    return Math.round(
      val / (waitTimes?.filter((item) => item[key]).length || 1)
    )
  }

  // TODO: can we move this to the queue stats component?
  const allQueues = {
    timeM: computeWaitTimeWithKey('waitTimeM'),
    timeS: computeWaitTimeWithKey('waitTimeS'),
  }

  /**
   * Handle booking display
   */

  return (
    <>
      <div>
        <div className="d-flex justify-content-between w-100 align-items-start">
          <div
            className={classNames(
              styles.queueXL,
              'justify-content-start me-2 me-lg-0 overflow-auto'
            )}
            style={{ zIndex: 0, paddingBottom: '10px', paddingRight: '8px' }}
          >
            {isBookingView ? (
              <>
                {bookingCounts?.map((range) => {
                  return (
                    <BookingStats
                      key={`booking-range-${range.name}`}
                      bookingTimeRange={range}
                      count={range?.count}
                    />
                  )
                })}
              </>
            ) : (
              <>
                <QueueStats
                  key={`queue-tab-all-queues`}
                  queue={DEFAULT_QUEUE_FILTER}
                  time={filterUnits(
                    getDisplayTimeDuration(
                      getDurationInSeconds(allQueues.timeM, allQueues.timeS),
                      'seconds',
                      2
                    ),
                    ['s']
                  )}
                  count={allQueuesCount}
                  selectedQueue={selectedQueue}
                  handleSelectQueue={(q) => {
                    dispatch(setSelectedQueueId(q.id))
                    dispatch(setPageNumber(1))
                  }}
                />

                {/* <Skeleton variant="rectangular" width={80} height={32} style={{borderRadius: 99}} /> */}
                {waitTimes?.map((waitTime: WaitTime, i) => (
                  <QueueStats
                    key={`queue-tab-${i}`}
                    queue={{ id: waitTime.queueId, name: waitTime.queueName }}
                    time={filterUnits(
                      getDisplayTimeDuration(
                        getDurationInSeconds(
                          waitTime.waitTimeM,
                          waitTime.waitTimeS
                        ),
                        'seconds',
                        2
                      ),
                      ['s']
                    )}
                    count={
                      waitTimesWithCount?.find(
                        (wt) => wt?.queueId === waitTime.queueId
                      )?.count
                    }
                    selectedQueue={selectedQueue}
                    handleSelectQueue={(q) => {
                      dispatch(setSelectedQueueId(q.id))
                      dispatch(setPageNumber(1))
                      mutateParties()
                    }}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
