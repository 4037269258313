import useVirtualWaitingRooms  from '@/hooks/api-hooks/useVirtualWaitingRooms';
import useSelectedLocation from './useSelectedLocation';
import { useMemo, useEffect } from 'react';
import { useTypedSelector } from '@/store/index';


export default function useSelectedVWR() {
  const { virtualWaitingRooms } = useVirtualWaitingRooms()
  const { selectedLocation } = useSelectedLocation()
  const { hasOnlyOneLocation, isUsingSharedSettings } = useTypedSelector((state) => state.pages.manage)

  // if using shared settings
  const selectedVWR = useMemo(() => {
    if (!selectedLocation?.isVwrEnabled) {
      return undefined
    }
    const businessVWR = virtualWaitingRooms?.find(
      (vwr) => vwr.vwrableType === 'business' && !vwr.deletedAt
    )
    const locationVWR = virtualWaitingRooms?.find(
      (vwr) => vwr.vwrableId === selectedLocation?.id && !vwr.deletedAt
    )
    if (!hasOnlyOneLocation && !isUsingSharedSettings && locationVWR) {
      return locationVWR
    }
    if ((hasOnlyOneLocation || isUsingSharedSettings) && businessVWR) {
      return businessVWR
    }
    return undefined
  }, [virtualWaitingRooms, selectedLocation, hasOnlyOneLocation, isUsingSharedSettings])

  return {
    selectedVWR,
  }
}