import React, { useCallback, useEffect, useMemo, useState } from 'react'
import WaitlistEntry from '@/components/pages/waitlist/WaitlistEntries/WaitlistEntry'
import Image from 'next/image'
import classNames from 'classnames'
import buttonStyles from '@/styles/Buttons.module.scss'
import { useDispatch } from 'react-redux'
import {
  clearFiltersAndSearch,
  setIsFilterModalOpen,
} from '@/store/slices/pages/waitlistSlice'
import { WaitlistEntriesPagination } from '@/components/pages/waitlist/WaitlistEntries/WaitlistEntriesPagination'
import { LinearProgress, Skeleton } from '@mui/material'
// import { useSwipeable } from "react-swipeable"
import { useTypedSelector } from '@/store/index'
import { usePhoneOnlyQuery } from '@/utils/responsive-queries'
import { Business } from '@/api/interface/business'
import { Party } from '@/api/interface/party'
import { Location } from '@/api/interface/location'
import { Queue } from '@/api/interface/queue'
import { WaitTime } from '@/api/interface/wait-time'
import textStrings from '@/consts/text-strings'
import useNewPartyAlert from '@/hooks/useNewPartyAlert'
import { ErrorBoundary } from '@/components/common/ErrorBoundary'
import useFilteredParties from '@/hooks/useFilteredParties'

interface Props {
  actions: {
    mutateParties: Function
    mutateWaitTimes: Function
    onSubmitUpdate: Function
    onSubmitCreate: Function
  }
  business: Business
  newPartySkeleton: Partial<Party>
  isPartiesLoading: boolean
  parties?: Array<Party>
  partiesMeta: any
  partyBeingDeleted: Party['id']
  selectedLocation: Location
  selectedQueue: Partial<Queue>
  waitTimes: Array<WaitTime>
}

export function WaitlistEntries({
  actions,
  business,
  newPartySkeleton,
  isPartiesLoading,
  // parties,
  partiesMeta,
  partyBeingDeleted,
  selectedLocation,
  selectedQueue,
  waitTimes,
}: Props) {
  const dispatch = useDispatch()
  const alerts = useNewPartyAlert()
  const [pauseForFilterUpdate, setPauseForFilterUpdate] = useState(false)

  const isPhoneUser = usePhoneOnlyQuery()

  const { isFiltered, filters } = useTypedSelector(
    (state) => state.pages.waitlist
  )
  const { filter: partiesParamsFilter } = useTypedSelector(
    (state) => state.pages.waitlist.partiesParams
  )
  const { parties, totalParties, mutateParties } = useFilteredParties()

  // allow user to pull down wait list entry table to refresh
  //   const swipeHandlers = useSwipeable({
  //     delta: { down: 30, left: 1200, right: 1200 },
  //     onSwipedDown: (_eventData) => {
  //       if (_eventData.velocity < 0.2) {
  //         actions.mutateParties()
  //       }
  //     },
  //   })

  const selectedWaitTime = useMemo(
    () => waitTimes.find((x) => x.queueId === selectedQueue?.id),
    [waitTimes, selectedQueue]
  )

  const shouldShowPagination = useMemo(() => {
    const partiesCount =
      isFiltered || partiesParamsFilter?.s?.length > 0
        ? totalParties
        : selectedWaitTime?.waitingPartiesInQueue
    // if (totalParties < 1) return false

    return selectedQueue?.name === textStrings.QUEUE_ALL
      ? partiesMeta?.total > partiesMeta?.perPage
      : partiesCount > partiesMeta?.perPage
  }, [partiesMeta, selectedWaitTime, selectedQueue, totalParties])

  const resetFilters = () => {}

  const retryFilters = () => {}

  return (
    <>
      {isPartiesLoading && <LinearProgress />}
      {shouldShowPagination && (
        <WaitlistEntriesPagination
          responseMeta={partiesMeta}
          newPartySkeleton={newPartySkeleton}
        />
      )}

      <section id="waitlist-entries-entry-section">
        {parties?.map(
          (party) =>
            party.id !== partyBeingDeleted && (
              <ErrorBoundary key={`party-${party.id}`} fallback={<div></div>}>
                <WaitlistEntry
                  actions={actions}
                  business={business}
                  key={`party-${party.id}`}
                  selectedLocation={selectedLocation}
                  party={party}
                />
              </ErrorBoundary>
            )
        )}

        {isPhoneUser && parties?.length >= 2 && (
          <p
            className={
              'd-flex w-100 text-center justify-content-center mb-20 mt-6 text-base'
            }
            style={{ color: '#b9b9b9', fontWeight: 500 }}
          >
            End of the line 🎉
          </p>
        )}
        {((!parties?.length && !newPartySkeleton && !isPartiesLoading) ||
          (parties?.length === 1 && partyBeingDeleted)) && (
          <div
            className="d-flex flex-column w-75 h-75 mx-auto text-center justify-content-center align-items-center"
            style={{ maxWidth: 300 }}
          >
            {isFiltered || pauseForFilterUpdate ? (
              <>
                <Image
                  src="/img/no-results.svg"
                  width="300"
                  height="300"
                  alt={'No results'}
                />
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  No results found...
                </p>
                <div className="w-100 w-md-50 mt-4">
                  <p className="mb-4">
                    Try editing your filters or search query.
                  </p>
                  <div className="d-flex align-items-center justify-content-between">
                    <button
                      className={classNames(
                        buttonStyles.grayBtn,
                        'btn w-50 me-2'
                      )}
                      onClick={async () => {
                        dispatch(clearFiltersAndSearch())
                        setPauseForFilterUpdate(true)
                        setTimeout(async () => {
                          await mutateParties()
                          setPauseForFilterUpdate(false)
                        }, 250)
                      }}
                    >
                      Clear All
                    </button>
                    <button
                      className={classNames(
                        buttonStyles.midnightBtn,
                        'btn w-50 me-2'
                      )}
                      onClick={() => {
                        dispatch(setIsFilterModalOpen(true))
                        actions.mutateParties()
                      }}
                    >
                      Try Again
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Image
                  src="/animations/nothing-here.gif"
                  width="250"
                  height="250"
                  alt={'No results'}
                />
                <p style={{ fontSize: 24, fontWeight: 'bold' }}>
                  It's quiet here...
                </p>
                <div className="w-100 w-md-50 mt-4">
                  <p>
                    There are no guests in{' '}
                    {selectedQueue?.name &&
                    selectedQueue?.name !== textStrings.QUEUE_ALL ? (
                      <span className="font-weight-bold">
                        {selectedQueue.name}
                      </span>
                    ) : (
                      'this waitlist'
                    )}
                    . Click the Add Guest button to get the party started.
                  </p>
                </div>
              </>
            )}
          </div>
        )}
        {newPartySkeleton && (
          <Skeleton
            animation="wave"
            sx={{ bgcolor: '#ececec' }}
            variant="rounded"
            width="calc(100%-110px)"
            height={100}
            style={{ marginTop: 16 }}
          />
        )}
      </section>

      {shouldShowPagination && (
        <WaitlistEntriesPagination
          responseMeta={partiesMeta}
          newPartySkeleton={newPartySkeleton}
        />
      )}
    </>
  )
}
