import { HELP_CENTER_LINK } from "@/consts/misc"
import useBusiness from "@/hooks/api-hooks/useBusiness"
import { useTypedSelector } from "@/store/index"
import { setIsSidebarOpen } from "@/store/slices/uiSlice"
import { twConfig } from "@/styles/tailwind-utils"
import { usePhoneOnlyQuery, useTabletPortraitOnly } from "@/utils/responsive-queries"
import { Drawer, IconButton } from "@mui/material"
import Link from "next/link"
import { useRouter } from "next/router"
import { HTMLAttributeAnchorTarget, ReactNode, useEffect, useMemo } from "react"
import {
  BarChart2,
  ChevronLeft,
  Clock,
  Users,
  LogOut,
  Settings,
  User,
} from "react-feather"
import { useDispatch } from "react-redux"
import PlanBadge from "@/components/pages/manage/subscription/PlanBadge"
import { ErrorBoundary } from "../ErrorBoundary"
import useBannerHeight from "@/hooks/useBannerHeight"

export const SidebarLink = ({
  text,
  href,
  icon,
  target,
}: {
  text: string
  href: string
  icon: ReactNode
  target?: HTMLAttributeAnchorTarget
}) => {
  const { pathname } = useRouter()
  const { isSidebarOpen } = useTypedSelector((state) => state.ui)
  const active = useMemo(() => pathname.startsWith(href), [pathname])

  return (
    <Link href={href} passHref rel="noopener noreferrer">
      <a
        className={active ? "bg-twilight inline-flex" : "inline-flex"}
        target={target}
      >
        <IconButton
          disableRipple={true}
          className={`text-white`}
          style={{
            paddingLeft: 28,
            paddingRight: 8,
            paddingTop: 14,
            paddingBottom: 14,
          }}
        >
          {icon}
        </IconButton>
        {isSidebarOpen && (
          <span
            className="text-lg text-ellipsis overflow-hidden whitespace-nowrap font-medium align-middle"
            style={{ lineHeight: 3, maxWidth: 180 }}
          >
            &nbsp;{text}
          </span>
        )}
      </a>
    </Link>
  )
}

export const SidebarNavContent = () => {
  const { business } = useBusiness()
  const { isSidebarOpen } = useTypedSelector((state) => state.ui)
  const dispatch = useDispatch()

  return (
    <>
      <section className="flex flex-col flex-wrap self-start w-full">
        {isSidebarOpen && (
          <div id="sidebar-biz-logo" className="m-3 flex">
            <img
              src={
                business?.media?.logo?.originalUrl ||
                "/logos/nextme/nextme-icon-light.svg"
              }
              style={{
                width: 60,
                height: 60,
                alignSelf: "center",
                borderRadius: "1rem",
                marginRight: 4,
              }}
            />
            <div className="flex flex-col p-2">
              <p
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                  whiteSpace: "nowrap",
                  maxWidth: 120,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {business?.name}
              </p>
              <ErrorBoundary fallback={''}>
                <PlanBadge />
              </ErrorBoundary>
            </div>
            <IconButton
              disableRipple={true}
              onClick={() => {
                dispatch(setIsSidebarOpen(false))
              }}
            >
              <ChevronLeft color="white" size={24} />
            </IconButton>
          </div>
        )}
        <SidebarLink href="/waitlist" text="Waitlist" icon={<Users />} />
        <SidebarLink href="/analytics" text="Analytics" icon={<BarChart2 />} />
        <SidebarLink href="/settings" text="Settings" icon={<Settings />} />
      </section>
      <section className="flex flex-col self-end w-full">
        <SidebarLink
          href="/business"
          text={business?.name || "Business"}
          icon={
            <img
              alt=""
              src={business?.media?.logo?.originalUrl || "/icons/business.svg"}
              style={{
                borderRadius: 4,
                width: 24,
                height: 24,
              }}
            />
          }
        />
        <SidebarLink href="/account" text="Account" icon={<User />} />
        <span id="intercom_launcher">
          <SidebarLink
            href={HELP_CENTER_LINK}
            text="Support"
            icon={
              <img
                alt=""
                src={"/icons/support.svg"}
              />
            }
            target="_blank"
          />
        </span>
        <SidebarLink href="/logout" text="Log Out" icon={<LogOut />} />
        <br />
      </section>
    </>
  )
}

export default function SidebarNav({ isBannerVisible }) {
  const dispatch = useDispatch()
  const { isSidebarOpen } = useTypedSelector((state) => state.ui)
  const isPhoneUser = usePhoneOnlyQuery()
  const bannerHeight = useBannerHeight()

  const topMargin = useMemo(() => {
    if (isPhoneUser) {
      return '0'
    }

    if (isSidebarOpen) {
      return '0'
    }

    return `${bannerHeight + 80}px`
  }, [isPhoneUser, bannerHeight, isSidebarOpen])

  return (
    <Drawer
      open={!isPhoneUser || isSidebarOpen}
      elevation={1}
      onClose={() => {
        dispatch(setIsSidebarOpen(false))
      }}
      sx={{
        "& .MuiPaper-root": {
          position: 'fixed', // position must remain fixed
          bgcolor: twConfig.theme.colors.midnight,
          color: twConfig.theme.colors.white,
          width: isSidebarOpen ? 270 : 80,
          paddingTop: !isSidebarOpen && "14px",
          display: "flex",
          justifyContent: "space-between",
          height: "-webkit-fill-available",
          marginTop: topMargin,
          // bottom: isBannerVisible ? '50px' : 0,
        },
      }}
      variant={isPhoneUser ? "temporary" : "permanent"}
      hideBackdrop={!isPhoneUser}
      ModalProps={{
        style: { display: !isSidebarOpen && "none" },
      }}
      {...(isPhoneUser && { keepMounted: true })}
    >
      <SidebarNavContent />
    </Drawer>
  )
}
