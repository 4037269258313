import { Listbox } from '@headlessui/react'
import styles from './ListboxField.module.scss'
import classNames from 'classnames'
import { useEffect, useState, Fragment } from 'react'

export interface ListboxFieldProps {
  label?: string
  name?: string
  value?: any
  options?: any[]
  handleChange?: Function
}

const ListboxField = ({
  label,
  name,
  value,
  options,
  handleChange,
}: ListboxFieldProps) => {
  const getSelectedValue = (value, options) => {
    return options?.find((option) => option.value === value)
  }
  const [selectedOption, setSelectedOption] = useState(
    getSelectedValue(value, options)
  )

  return (
    <Listbox
      value={selectedOption}
      onChange={(value) => {
        setSelectedOption(value)
        handleChange(value)
      }}
    >
      <div
        className="relative mt-2"
        style={{
          height: '100px',
        }}
      >
        <Listbox.Options
          static
          className={classNames('group', styles.listboxOptions)}
        >
          {options?.map((option) => {
            return (
              <Listbox.Option
                key={`option=${option.value}`}
                value={option}
                as={Fragment}
              >
                {({ active, selected }) => (
                  <li
                    className={classNames(
                      styles.listboxOption,
                      selected ? styles.listboxOptionSelected : null,
                      active ? styles.listboxOptionSelected : null
                    )}
                  >
                    {option.label}
                  </li>
                )}
              </Listbox.Option>
            )
          })}
        </Listbox.Options>
      </div>
    </Listbox>
  )
}

export default ListboxField
